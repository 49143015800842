import { Expose, Transform, Type } from 'class-transformer';

export class UserProfile {
  public id!: number;
  @Expose()
  public nationality!: string;
  @Expose()
  public passportId!: string;
  @Expose()
  // @Type(() => Date)
  // @Transform((param) => new Date(param.value))
  public dateOfBirth!: string;
  @Expose()
  public emergencyContact!: string;
  @Expose()
  public medicalAidNumber!: string;
  @Expose()
  public medicalAidProvider!: string;
  @Expose()
  public medicalAidEmergency!: string;
  @Expose()
  public travelInsurance!: string;
  @Expose()
  public insuranceProvider!: string;
  @Expose()
  public insuranceNumber!: string;
}
