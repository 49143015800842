import Component, { mixins } from 'vue-class-component';

import LoadMixin from './load.mixin';

@Component
export default class StyleMixin extends mixins(LoadMixin) {
  btnColours(color: string, shade: number, extra = ''): string {
    return `wizard-footer-right
     text-white bg-${color}-${shade}
      hover:bg-${color}-${shade - 100} 
      focus:outline-none
       border-${color}-${shade}
        hover:border-${color}-${shade - 100}
         focus:shadow-outline-${color}
          active:bg-${color}-${shade + 100}  m-2 ${extra}`;
  }
}
