
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class DescriptionList extends Vue {
  @Prop({ default: 'Title' }) title: string;

  @Prop({ default: 'SubTitle' }) subTitle: string;

  @Prop() content: any;

  @Prop() schema: any;

  @Prop() accommodationAddress: string;

  hasSchema = false;

  counter = 0;

  titleToCase(value: string): string {
    if (value) {
      if (value.includes('ID')) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      const result = value.replace(/([A-Z])/g, ' $1');
      const finalResult = value.charAt(0).toUpperCase() + result.slice(1);

      if (finalResult === 'Country') {
        return 'Country of Address';
      }
      if (finalResult === 'Resident') {
        return 'Country of Residence';
      }

      return finalResult;
    }
    return '';
  }

  count() {
    let count = 0;
    this.counter += 1;
    if (this.counter === 0) {
      count = this.counter;
      return count;
    }
    return this.counter;
  }

  get getContent(): any {
    if (this.schema) {
      this.hasSchema = true;
      return this.schema;
    }
    return this.content;
  }
}
