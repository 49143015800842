
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import Stepper from '@/app/components/stepper/Stepper.vue';
import DescriptionList from '@/components/description-list/DescriptionList.vue';
import GuestBookingPage from '@/modules/guests/pages/GuestBookingPage.vue';

@Component({
  components: {
    Stepper,
    DescriptionList,
    GuestBookingPage,
  },
})
export default class GuestForm extends Vue {
  @Prop() title!: string;
  @Prop() subTitle!: string;
  @Prop() steps!: string[];
  @PropSync('currentStep') currentStepSync!: number;

  progress = 50;

  get progressColor() {
    // if (this.currentStepSync <= 25) {
    return 'teal-300';
    // }
    // if (this.currentStepSync <= 75) {
    //   return 'teal-500';
    // }
    // return 'teal-700';
  }
}
