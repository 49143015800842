
import { Component, PropSync, Ref, Vue } from 'vue-property-decorator';
import { IBookingDto, IGuestDto, IMinorDto } from '../../../app/entities';
import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion';
import Accordion from '@/components/accordion/Accordion.vue';
import VsDivider from '../../../components/vx-divider/vsDivider.vue';
import { POSITION } from 'vue-toastification';
import { guestModule } from '@/store/modules/guest.module';
import { Loading } from '../../auth/store/auth.service';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import MinorSchema from '@/modules/guests/schema/minor.schema';
import MinorDto from '../../../app/entities/tenant/minor.dto';
import { GuestDto } from '../../../app/dto/guest.dto';
import isEmpty from '@/app/utils/object.utils';

interface minorForm {
  formSubmitted(): boolean;
  hideErrors(): any;
  hasErrors: boolean;
}

@Component({
  components: {
    BadgerAccordion,
    BadgerAccordionItem,
    Accordion,
    VsDivider,
  },
})
export default class GuestInviteCard extends Vue {
  @PropSync('booking') bookingSync!: IBookingDto;

  @PropSync('guests') guestsSync!: GuestDto[];

  @PropSync('currentGuest') currentGuestSync!: GuestDto;

  @PropSync('invited') invitedGuests!: GuestDto[];

  public $refs: Vue['$refs'] & {
    minorForm: minorForm;
  };

  openIcon = '<i class="fas fa-chevron-down"></i>';
  closedIcon = '<i class="fas fa-chevron-up"></i>';

  minor = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    requirements: '',
  };

  minorSchema = MinorSchema;

  get hasMinorInvitations() {
    return this.bookingSync.minorInvitations > 0;
  }

  get numberOfMinors() {
    return this.bookingSync.numberOfMinors - this.bookingSync.minorInvitations;
  }

  get numberOfGuests() {
    return this.bookingSync.guest.length - this.bookingSync.invitations;
  }

  nameChange(event: string, ind: number) {
    const split = event.split(' ');
    /*     if (this.bookingSync.guest) {
      this.bookingSync.guest[ind].firstName = split[0];
      this.bookingSync.guest[ind].lastName = split[1];
    } */
    if (this.bookingSync.guest) {
      this.bookingSync.guest[ind].firstName = split[0];
      this.bookingSync.guest[ind].lastName = split[1];
    }
  }

  // addMinor(index: number) {
  //   const minor = new MinorDto();
  //   // IMinorDto = {
  //   //   guestId: this.bookingSync.guest[index].id as string,
  //   // };

  //   this.bookingSync.guest[index].minor?.push(minor);
  // }
  async addMinor() {
    const minor = new MinorDto();
    const minorForm: minorForm = this.$refs.minorForm;
    const isValidated = await minorForm.formSubmitted();
    if (!minorForm.hasErrors) {
      const fullName: string = this.minor.firstName;
      minor.firstName = this.minor.firstName;
      minor.lastName = this.minor.lastName;
      minor.requirements = this.minor.requirements;
      minor.dateOfBirth = this.minor.dateOfBirth;
      this.currentGuestSync.minor?.push(minor);
      this.minor = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        requirements: '',
      };
      minorForm.hideErrors();

      if (this.currentGuestSync.minor.length === this.bookingSync.numberOfMinors) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'All minors have been added to the booking',
              icon: 'concierge-bell',
              variant: 'success',
            },
          },
          {
            position: POSITION.TOP_RIGHT,
          },
        );
      }
      this.bookingSync.minorInvitations--;
    }
  }

  removeMinor(index: number) {
    this.currentGuestSync.minor.splice(index, 1);
    this.bookingSync.minorInvitations++;
  }

  @Loading
  async inviteGuest(ind: number) {
    if (isEmpty(this.bookingSync.guest[ind].email) && isEmpty(this.bookingSync.guest[ind].phone?.number)) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: `Please fill in either phone number or email before inviting guest`,
            variant: 'error',
          },
        },
        {
          position: POSITION.TOP_RIGHT,
        },
      );
      return;
    }
    if (this.bookingSync.guest) {
      if (this.bookingSync.guest[ind].email == '') {
        this.bookingSync.guest[ind].email = undefined;
      }
      const res = await guestModule.updateGuestBooking({ payload: this.bookingSync.guest[ind], id: this.bookingSync.id });
      this.bookingSync.guest[ind].invited = true;
      this.bookingSync.invitations -= 1;
    }
  }
}
