
import { Component, Ref, Watch, Prop } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import '@/modules/organisation/schema/bookingDetails';
import { mixins } from 'vue-class-component';
import { Route } from 'vue-router';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import SchemaMixin from '@/shared/mixins/schema.mixins';
import StyleMixin from '@/shared/mixins/style.mixin';
import * as _ from 'lodash';
import service from '@/services/app-http-client';
import { Loading } from '@/modules/auth/store/auth.service';
import userModule from '@/store/modules/user/user.module';
import DescriptionList from '@/components/description-list/DescriptionList.vue';
import Signature from '@/components/signature/Signature.vue';
import Accordion from '@/components/accordion/Accordion.vue';
import { guestModule } from '@/store/modules/guest.module';
import ToastificationContent from '@/app/components/toastification/ToastificationContent.vue';
import { POSITION } from 'vue-toastification';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { IBookingDto, IGuestDto, IMinorDto } from '@/app/entities';
import { Auth } from '@/store/modules/auth.module';
import { loginRequest } from '@/app/config/msal/request.config';
import dayjs from 'dayjs';
import GuestInviteCard from '@/modules/guests/components/GuestInviteCard.vue';
import { mapState } from 'vuex';
import VsDivider from '../../../components/vx-divider/vsDivider.vue';
import GuestForm from '@/modules/guests/components/GuestFormShell.vue';
import Stepper from '@/app/components/stepper/Stepper.vue';
import { plainToClass } from 'class-transformer';
import { UserProfile } from '@/app/entities/user/user-profile.dto';
import { Datepicker } from '@livelybone/vue-datepicker';
import { GuestBookingSchema } from '@/modules/guests/schema/guest-booking.schema';
import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';
import Guest, { IGuestAddress } from '../../../app/entities/tenant/guest.dto';
import { indexOf } from 'lodash';

@Component({
  metaInfo: {
    title: 'Booking Check-in',
    // override the parent template and just use the above title only
  },
  components: {
    DescriptionList,
    VuePerfectScrollbar,
    Signature,
    Accordion,
    GuestInviteCard,
    VsDivider,
    Stepper,
    GuestForm,
    Datepicker,
  },
  computed: {
    ...mapState('guest', ['guests', 'booking']),
  },
})
export default class GuestBookingPage extends mixins(SchemaMixin, StyleMixin) {
  @Ref('signUpForm') signUpForm: any;

  @Ref() readonly formWizard!: any;

  date = {
    value: '12/43/4544',
  };

  schema: any = [];

  loading = true;

  active = true;

  booking!: IBookingDto;

  accommodation: AccommodationDto;

  color = 'success';

  guests!: IGuestDto[];

  signatureBool = false;

  scrolledToBottom = false;

  settings = {
    maxScrollbarLength: 60,
  };

  signature = '';

  documentCheckbox = false;

  finalCheckBox = false;

  activeIndex = 0;

  activeIndemnity = 0;

  errorSeen = false;

  logo = {};

  steps = ['Booking Details', 'Personal Information', 'Details Confirmation', 'Indemnity - T&C'];

  indemnityScrollCheck: boolean[] = [];

  get bookingIndemnity() {
    return this.booking.indemnity;
  }

  get currentIndex() {
    return this.activeIndex * 20;
  }

  @Watch('guest')
  handleGuest(guest: Guest) {
    guestModule.setGuest(guest);
  }

  guest = new Guest();

  get invitedGuests() {
    return this.booking.guest.filter((guest: any) => {
      if (guest.invited && guest.id !== this.guest.id) {
        return guest;
      }
    });
  }

  get uninvitedGuests() {
    var a = this.booking.guest.filter((guest: any) => {
      if (!guest.invited) {
        return guest;
      }
    });
    if (a == []) {
      return a;
    } else {
      return this.booking.guest;
    }
    /*  return this.booking.guest.filter((guest: any) => {
      if (!guest.invited) {
        return guest;
      }
    }); */
  }

  get accommodationCountry() {
    return this.booking.accommodation.address.formatted_address.split(',').slice(-1)[0];
  }

  @Watch('$refs.formWizard.activeTabIndex', { deep: true })
  activeTabIndex(val: number) {
    if (val) {
      this.activeIndex = val * 5;
    }
  }

  @Watch('activeIndex')
  scrollToTop(): void {
    document.body.scrollTop = 0;
    this.$nextTick(() => {
      if (this.activeIndex == 3) {
        this.shortCheck();
      }
    });
  }

  get hasSignature() {
    return this.signatureBool;
  }

  get progressColor() {
    if (this.activeIndex <= 25) {
      return 'red-400';
    }
    if (this.activeIndex <= 75) {
      return 'trd-600';
    }
    return 'red-800';
  }
  shortCheck() {
    for (var key in this.$refs['indemnity-scroll'] as any) {
      (this.$refs['indemnity-scroll'] as any)[key].update();
    }
  }
  acceptFunc(index: number) {
    this.indemnityScrollCheck[index] = true;
    this.scrolledToBottom = this.indemnityScrollCheck.every(Boolean);
  }

  scrollUp() {
    this.scrolledToBottom = false;
  }

  schemaList(schemaObj: any): any {
    const x = this.getFlatSchema(schemaObj);

    return x;
  }
  async created(): Promise<void> {
    const to = this.$router.currentRoute;
    const token = to.query.id as string;
    const loading = this.$vs.loading({
      type: 'circles',
      color: 'primary',
      text: 'Opening your pre check-in...',
    });
    const organisationId = to.query.org;
    localStorage.clear();
    loginRequest.state = 'guest';
    localStorage.setItem('pendingInvite', token);
    tenantModule.setTenantId([organisationId as string]);
    this.$msal.data.accessToken = token;

    try {
      await guestModule.fetchGuest({
        token,
      });
    } catch (error: any) {
      loading.close();
      this.$router.replace('/check-in-not-found');
      // throw new Error(error)
      return;
    }

    this.guest = guestModule.guest;

    if (this.guest === undefined) {
      loading.close();
      this.$router.replace('/check-in-not-found');
    }

    this.guest.address = {} as IGuestAddress;

    if (!this.bookingIndemnity.length) {
      this.scrolledToBottom = true;
    }

    this.bookingIndemnity.forEach(() => this.indemnityScrollCheck.push(false));
    // this.guest = guestModule.guest;
    if (!this.guest.address?.country) {
      this.guest.address.country = 'South Africa';
      this.guest.nationality = 'South Africa';
    }

    this.schema = GuestBookingSchema();
    guestModule.setIsCheckIn(true);

    const isPrimary = this.booking.invitations > 0 && this.guest.isPrimary;

    if (isPrimary || this.booking.minorInvitations > 0) {
      this.steps.push('Add Travel Companions');
    }

    this.loading = false;
    loading.close();

    // if (this.guest.status !== 'PENDING') {
    //   this.$swal('Checked in already', 'You have already checked in as this guest', 'error');
    //   this.$router.push('/');
    // }

    if (this.guest.status === 'CHECKEDIN') {
      this.$swal('Checked in already', 'You have already checked in as this guest', 'error');
      this.$router.push('/');
    }

    if (this.guest.status === 'PRECHECKEDIN' && this.booking.accommodation.healthQuestions.length > 0) {
      this.$router.replace(`checkin?id=${this.guest.token}&org=${this.$router.currentRoute.query.org}&preCheck=true`);
    }

    if (this.guest.status === 'PRECHECKEDIN' && this.booking.accommodation.healthQuestions.length === 0) {
      this.$swal('Health questionnaire no longer required', 'Please complete your check-in again. We apologise for the inconvenience.');
    }

    if (this.booking.accommodation.guestConfirmation.confirmationSchema !== undefined) {
      this.removeOptionFromForm(this.booking.accommodation.guestConfirmation.confirmationSchema);
    }
  }

  // **** Form delete

  removeOptionFromForm(fieldsToRemove: string[]) {
    if (fieldsToRemove.includes('arrivalTime')) {
      fieldsToRemove.splice(fieldsToRemove.indexOf('arrivalTime'), 1);
      fieldsToRemove.unshift('arrivalTimeEnd', 'arrivalTimeStart');
    }
    fieldsToRemove.forEach((nameOfField: string) => {
      // let customForm: any = this.schema;
      this.schema.forEach((el: any) => {
        if (el.children) {
          el.children.forEach((child: any) => {
            if (child.children) {
              child.children.forEach((subChild: any) => {
                if (subChild.name === nameOfField) {
                  child.children.splice(child.children.indexOf(subChild), 1);
                }
              });
            } else if (child.name === nameOfField) {
              el.children.splice(el.children.indexOf(child), 1);
            } else return;

            if (child.children && child.children.length === 0) {
              el.children.splice(el.children.indexOf(child), 1);
            }
          });
        } else {
          if (el.options) {
            let optionsChildren = el.options[0].children;

            optionsChildren.forEach((child: any) => {
              if (child.name === nameOfField || child.name.includes(nameOfField)) {
                optionsChildren.splice(optionsChildren.indexOf(child), 1);
              }
            });
            // Clean up description and separator and set validation to true
            if (el.name === 'profile' && el.options[0].children.length === 0) {
              const profileHR = this.schema.find((div: any) => {
                return div.name === 'profileHR';
              });
              this.schema.splice(this.schema.indexOf(profileHR), 3);
              guestModule.setMedicalFormIsValid(true);
            }
            if (el.name === 'guestArrival' && el.options[0].children.length === 0) {
              const guestArrivalHR = this.schema.find((div: any) => {
                return div.name === 'guestArrivalHR';
              });
              this.schema.splice(this.schema.indexOf(guestArrivalHR), 3);
              guestModule.setArrivalFormIsValid(true);
            }
            if (el.name === 'guestAdditionalInformation' && el.options[0].children.length === 0) {
              const additionalInformationHR = this.schema.find((div: any) => {
                return div.name === 'additionalInformationHR';
              });
              this.schema.splice(this.schema.indexOf(additionalInformationHR), 3);
              guestModule.setExtraInfoFormIsValid(true);
            }
          }
        }
      });
    });
  }

  /**********************************/

  get mapMinors(): string[][] | [] {
    return this.guest.minor?.map((minor: Partial<IMinorDto>) => {
      return Object.keys(minor).filter((prop) => prop !== 'id');
    });
  }

  get mapBookingInfo() {
    return {
      bookingReference: this.booking.bookingReference,
      firstName: this.guest.firstName as string,
      lastName: this.guest.lastName as string,
      accommodation: this?.booking?.accommodation?.name,
      email: this.guest?.email,
      // guests: `${this.booking.numberOfGuests} adults, ${this.booking.numberOfMinors} children`,
      guests: this.booking.numberOfGuests + ' adults, ' + this.booking.numberOfMinors + ' children',
      total: this.booking?.numberOfGuests + this.booking?.numberOfMinors,
      'check-In':
        dayjs(this.booking?.checkInDate).format('DD MMMM YYYY') +
        ' From: ' +
        this.booking.accommodation.checkInTimeStart.slice(0, 5) +
        ' To: ' +
        this.booking.accommodation.checkInTimeEnd.slice(0, 5),
      'check-Out':
        dayjs(this.booking?.checkOutDate).format('DD MMMM YYYY') + ' Before: ' + this.booking.accommodation.checkOutTime.slice(0, 5),
    };
  }

  get joinedAddress() {
    if (this.guest.address?.addressLineTwo !== undefined) {
      return this.guest.address?.addressLineOne + ', ' + this.guest.address?.addressLineTwo;
    } else {
      return this.guest.address?.addressLineOne;
    }
  }

  get mapGuest() {
    return {
      bookingReference: this.booking.bookingReference,
      firstName: this.guest.firstName as string,
      lastName: this.guest.lastName as string,
      email: this.guest?.email,
      phone: this.guest?.phone.numberFormatted,
      address: this.joinedAddress,
      city: this.guest.address?.city,
      code: this.guest.address?.code,
      country: this.guest.address?.country,
      /*       [`resident of ${this.booking.accommodation.address.formatted_address.split(',').slice(-1)[0]}`]: this.guest?.resident,
       */ nationality: this.guest?.nationality,
      // 'residential Address': Object.values(this.guest.address).join(', '),
      resident: this.guest.resident,
      'passport or ID number': this.guest?.passportId,
      'date of birth': dayjs(this.guest?.dateOfBirth).format('DD MMM YYYY'),
      'travel Insurance': this.guest?.profile?.travelInsurance,
      'medical aid provider': this.guest?.profile?.medicalAidProvider,
      'medical aid policy number': this.guest?.profile?.medicalAidNumber,
      'medical aid emergency contact number': this.guest?.profile?.medicalAidEmergency.numberFormatted,
      'next of kin contact number': this.guest?.profile?.emergencyContact.numberFormatted,
      'dietary requirements': this.guest?.profile?.dietaryRequirements,
      allergies: this.guest?.profile?.allergies,
      'medical conditions': this.guest?.profile?.medicalConditions,
      'special requirements': this.guest?.profile?.specialRequirements,
      'vaccinated against COVID-19': this.guest?.profile?.vaccinated,
      'cOVID-19 vaccines up to date': this.guest?.profile?.vaccineUpToDate,
      'assistance with luggage': this.guest?.guestArrival?.luggageHandling,
      'approximate arrival time from': this.guest?.guestArrival?.arrivalTimeStart,
      'approximate arrival time to': this.guest?.guestArrival?.arrivalTimeEnd,
      'mode of transport': this.guest?.guestArrival?.modeTransport,
      'vehicle registration': this.guest?.guestArrival?.vehicleRegistration,
      'flight number': this.guest?.guestArrival?.flightNumber,
      airline: this.guest?.guestArrival?.airline,
      'bed setup': this.guest?.guestAdditionalInformation?.bedConfig,
      'guests sharing a room with you': this.guest?.guestAdditionalInformation?.roomSharing,
      'cots for infants': this.guest?.guestAdditionalInformation?.infantCots,
      'bush dinner': this.guest?.guestAdditionalInformation?.bushDinner,
      'bush walk': this.guest?.guestAdditionalInformation?.bushWalk,
      'celebrating special occassion': this.guest?.guestAdditionalInformation?.specialOccasion,
      'specific interests': this.guest?.guestAdditionalInformation?.specificInterests,
      'carrying a firearm': this.guest?.guestAdditionalInformation?.firearm,
    };
  }
  validatedSubmit() {
    const load = this.$vs.loading();
    if (this.activeIndex === 3) {
      if (this.documentCheckbox === false || !this.hasSignature) {
        this.$swal(
          'Terms not accepted',
          'Please read through the terms & conditions before accepting and signing in the space provided.',
          'error',
        );
        load.close();
        return;
      }
    }
    if (this.booking.invitations > 0 && this.guest.isPrimary) {
      this.$swal('Error submitting', 'Please invite all the other adults guests for this booking', 'info');
      load.close();
      return;
    }
    this.submit();
    load.close();
  }

  scrollToError($formEl: any) {
    const errorWrapperEl = $formEl.querySelector('[data-has-errors="true"]');
    const errorWrapperInput = errorWrapperEl && errorWrapperEl.querySelector('input,select,textarea');
    if (errorWrapperInput) {
      errorWrapperInput.focus();
      errorWrapperInput.scrollIntoView({ block: 'center' });
    }
  }

  async validateForm(data: any = null) {
    const load = this.$vs.loading();
    if (this.activeIndex === 3) {
      if (this.documentCheckbox === false || !this.hasSignature) {
        this.$swal(
          'Terms not accepted',
          'Please read through the terms & conditions before accepting and signing in the space provided.',
          'error',
        );
        load.close();
        return;
      }
    }
    const isPersonalValid = guestModule.personalFormValid;
    // const isArrivalValid = guestModule.arrivalFormValid;
    // const isMedicalValid = guestModule.medicalFormValid;
    // const isExtraInfoValid = guestModule.extraInfoFormValid;
    let dobValid = guestModule.dobValid;
    const personalForm = this.$refs.personal as any;
    const address = document.getElementById('address-wrapper');
    if (address === null) guestModule.setPersonalFormIsValid(true);
    if (personalForm) {
      const dobInput = document.querySelector('.dob-picker') as any;
      if (dobInput === null) dobValid = true;
      await personalForm.formSubmitted();
      // if (!isMedicalValid || !isExtraInfoValid || !isPersonalValid || !isArrivalValid || !dobValid)
      if (personalForm.hasErrors || !isPersonalValid || !dobValid) {
        this.scrollToError(personalForm.$el);
        load.close();
        if (!dobValid) {
          if (dobInput) {
            dobInput.focus();
            dobInput.scrollIntoView({ block: 'center' });
            if (!this.errorSeen) {
              const dobValidation = document.querySelector('.validation') as any;
              dobValidation.innerHTML = 'Date of birth required';
              this.errorSeen = true;
            }
          }
        }
        return this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Required Fields',
              text: 'Please fill all required fields',
              icon: 'concierge-bell',
              variant: 'danger',
            },
          },
          {
            position: POSITION.BOTTOM_RIGHT,
          },
        );
      } else {
        await this.$formulate.submit('personalName');
        this.activeIndex += 1;
        load.close();
        return;
      }
      /* else {
        const result = await personalForm.formSubmitted();
      } */
    }
    if (data && data.file) this.logo = data.file[0].url;
    this.activeIndex += 1;
    load.close();
  }

  @Loading
  async singUpGuest(props: { nextTab: () => void }): Promise<void> {
    // const load = this.$vs.loading({ target: this.$refs.modal, type: 'circles' });

    try {
      loginRequest.state = 'guest';

      const response = await this.$msal.signIn({ state: 'guest' });

      await Auth.login(response as any);
      if (!userModule.user) {
        throw new Error('No user');
      }
      userModule.setMember(userModule.user.member);

      await userModule.updateGuestUser();
      // userModule.s(RoleType.Guest);
      // load.close();
      props.nextTab();
    } catch (error: any) {
      throw new Error(error);
    }
  }

  @Loading
  async registerGuest(): Promise<void> {
    const response = await this.$msal.acquireToken();

    if (!response) {
      throw new Error('Login failed');
    }

    await Auth.login(response);

    const validationCheck = this.signUpForm.formSubmitted();
    if (!validationCheck) {
      throw new Error('Please complete the sign up form');
    }

    return this.submit();
  }

  async submit(): Promise<any> {
    let load;
    if (this.booking.accommodation.healthQuestions.length > 0) {
      load = this.$vs.loading({
        type: 'circles',
        color: 'primary',
        text: 'Submitting pre check-in...',
      });
    } else {
      load = this.$vs.loading({
        type: 'circles',
        color: 'primary',
        text: 'Submitting check-in...',
      });
    }
    const isNotLastPerson = this.booking.guest
      .filter((v) => {
        return v.id !== this.guest.id;
      })
      .some((v: any) => {
        return v.status === 'PENDING';
      });
    if (!isNotLastPerson && this.booking.minorInvitations > 0) {
      load.close();
      return this.$swal(
        'Outstanding Minor',
        'You are the last adult guest to check-in for this booking. Please add the outstanding minor&#39;s details before continuing.',
        'error',
      );
    }

    try {
      this.guest;

      if (this.booking.accommodation.healthQuestions.length === 0) this.guest.status = 'CHECKEDIN';
      else this.guest.status = 'PRECHECKEDIN';

      if (!this.hasSignature) {
        return;
      }
      this.guest.signature = await guestModule.uploadImage({
        signature: this.guest.signature,
        id: this.guest.id,
        bookingId: this.guest.bookingId,
        accommodationId: this?.booking?.accommodationId,
        tenantId: this?.booking?.tenantId,
      });

      const identification = this.guest.travelIdDocument?.results[0].url;

      // if (!identification) {
      //   throw new Error('Missing identification document please upload');
      // }
      // if (this.guest.resident === 'South Africa') {
      //   this.guest.resident = true;
      // } else {
      //   this.guest.resident = false;
      // }
      const vaccinationProof = this.guest.profile?.vaccinationProof?.results[0].url;
      if (vaccinationProof !== undefined) {
        this.guest.profile.vaccinationProof = vaccinationProof;
      }
      this.guest.address.street = this.joinedAddress;
      this.guest.identification = identification;
      this.guest.dietaryRequirements = this.guest.profile?.dietaryRequirements;
      this.guest.luggageHandling = this.guest.guestArrival?.luggageHandling;
      const payload = _.omit(this.guest, 'travelIdDocument');
      let profile = plainToClass(UserProfile, payload, { excludeExtraneousValues: true });
      const trimmedProfile = Object.fromEntries(Object.entries(profile).filter(([__, v]) => v != null));
      Object.assign(payload.profile, trimmedProfile);
      const response = await service.put(
        `/guest/update/${payload.id}`,
        { guestInfo: payload, profile: profile, minorInvitations: this.booking.minorInvitations },
        {
          headers: {
            'x-organisation-id': `${tenantModule.apiKey}`,
            Authorization: `Bearer ${payload.token}`,
          },
        },
      );
      guestModule.setCurrentBooking(response.data);
      const currentDate = dayjs();
      const checkinDate = dayjs(this.booking.checkInDate).add(1, 'day');
      const timeDifference = currentDate.diff(checkinDate) * -1;
      if (timeDifference <= 86400000 && this.booking.accommodation.healthQuestions.length > 0) {
        load.close();
        return this.$router.replace(`checkin?id=${this.guest.token}&org=${this.$router.currentRoute.query.org}&preCheck=true`);
      }
      if (this.$router.currentRoute.query.sameDay) {
        load.close();
        return this.$router.replace(`checkin?id=${this.guest.token}&org=${this.$router.currentRoute.query.org}`);
      }
      if (this.$router.currentRoute.query.preCheck && this.booking.accommodation.healthQuestions.length > 0) {
        load.close();
        return this.$router.replace(`checkin?id=${this.guest.token}&org=${this.$router.currentRoute.query.org}&preCheck=true`);
      }
      load.close();

      if (this.booking.accommodation.healthQuestions.length > 0) {
        this.$swal
          .fire({
            title: 'Pre-check-in complete!',
            text: 'Your details have been submitted to your accommodation.',
            icon: 'success',
            confirmButtonText: 'Ok',
            cancelButtonColor: 'red',
          })
          .then(() => {
            this.$router.push('/checkin-confirmation');
          });
      } else {
        this.$swal
          .fire({
            title: 'Check-in complete!',
            text: 'Your details have been submitted to your accommodation.',
            icon: 'success',
            confirmButtonText: 'Ok',
            cancelButtonColor: 'red',
          })
          .then(() => {
            this.$router.push('/checkin-complete');
          });
      }
    } catch (error) {
      load.close();
      this.error(error);
      console.log(error);
      // throw new Error(error);
    }
  }

  routeUser(): Promise<Route> {
    return this.$router.push('/guests');
  }
  get backgroundBell() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/wave-gray.webp');
  }
  get background() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/wave-gray.webp');
  }

  async cancelRedirect() {
    const response = await this.$swal({
      title: 'Cancel check-in?',
      text: 'Are you sure you want to leave this booking check-in?',
      icon: 'question',
      showConfirmButton: true,
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
    if (response.isConfirmed) {
      this.$router.push('/');
    }
  }
}
